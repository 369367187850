import { useModalState } from "@clipboard-health/ui-react";
import RateReviewIcon from "@mui/icons-material/RateReview";
import { Button, Drawer } from "@mui/material";

import { ReviewCommentForm } from "./Comments";

interface AskQuestionFormAndButtonProps {
  workplaceId: string;
  onSubmit: () => void;
}

export function AskQuestionFormAndButton(props: AskQuestionFormAndButtonProps) {
  const { workplaceId, onSubmit } = props;
  const drawerModalState = useModalState();

  return (
    <>
      <Button
        startIcon={<RateReviewIcon />}
        variant="outlined"
        sx={{ width: "fit-content" }}
        onClick={() => {
          drawerModalState.openModal();
        }}
      >
        Ask a Question
      </Button>
      <Drawer
        open={drawerModalState.modalIsOpen}
        anchor="bottom"
        sx={{ zIndex: (theme) => theme.zIndex.modal }}
        PaperProps={{
          sx: {
            paddingX: 1,
            paddingTop: 1,
          },
        }}
        onClose={() => {
          drawerModalState.closeModal();
        }}
      >
        <ReviewCommentForm
          workplaceId={workplaceId}
          inputPlaceholder="Ask a Question"
          anonymousCheckboxLabel="I want my question to be anonymous"
          onSubmit={() => {
            drawerModalState.closeModal();
            onSubmit();
          }}
        />
      </Drawer>
    </>
  );
}
