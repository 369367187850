import { type UseModalState } from "@clipboard-health/ui-react";
import { DialogContent } from "@mui/material";
import { FullScreenDialog } from "@src/appV2/lib/Dialogs";

import { FullScreenDialogTitle } from "../../../lib/Dialogs/FullScreen/DialogTitle";
import { type ReviewQuestion } from "../../api/useGetQuestions";
import { ReviewQuestionnaireForm } from "./ReviewQuestionnaireForm";

interface ReviewQuestionnaireDialogProps {
  modalState: UseModalState;
  rating: number;
  workPlaceDetails: {
    id: string;
    name: string;
    type: string;
  };
  questions: ReviewQuestion[];
  onSuccess: () => void;
  reviewDate?: Date;
}

export function ReviewQuestionnaireDialog(props: ReviewQuestionnaireDialogProps) {
  const { modalState, rating, workPlaceDetails, questions, onSuccess, reviewDate } = props;

  return (
    <FullScreenDialog modalState={modalState}>
      <FullScreenDialogTitle onClose={modalState.closeModal}>
        {workPlaceDetails.name}
      </FullScreenDialogTitle>
      <DialogContent>
        <ReviewQuestionnaireForm
          rating={rating}
          questions={questions}
          workPlaceDetails={workPlaceDetails}
          reviewDate={reviewDate}
          onSuccess={() => {
            modalState.closeModal();
            onSuccess();
          }}
        />
      </DialogContent>
    </FullScreenDialog>
  );
}
