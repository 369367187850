import { isDefined } from "@clipboard-health/util-ts";
import { Rating } from "@mui/material";
import { type MutableRefObject } from "react";
import { useController, type UseControllerProps } from "react-hook-form";

type RatingFieldProps<T extends { rating: number }> = UseControllerProps<T>;

export function RatingField<T extends { rating: number }>(
  props: RatingFieldProps<T> & {
    formRef?: MutableRefObject<HTMLFormElement | undefined>;
  }
) {
  const { name, formRef } = props;
  const {
    field,
    formState: { isSubmitting },
  } = useController<T>({
    name,
  });

  return (
    <Rating
      {...field}
      disabled={isSubmitting}
      size="large"
      sx={{
        color: (theme) => theme.palette.primary.main,
        ".MuiRating-icon": {
          color: (theme) => theme.palette.primary.main,
        },
      }}
      value={field.value}
      onChange={(_event, newValue) => {
        field.onChange(newValue ?? 0);
        if (isDefined(formRef)) {
          formRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
        }
      }}
    />
  );
}
