import { useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, Button, Divider, Skeleton, Stack } from "@mui/material";
import { Fragment, useState } from "react";

import {
  type WorkplaceCommentsResponse,
  type WorkplaceCommentType,
} from "../../api/useWorkplaceComments";
import { CommentRepliesDialog } from "../Replies";
import { WorkerAvatar } from "../WorkerAvatar";
import { CommentCard } from "./CommentCard";

interface CommentsListProps {
  workPlaceDetails: {
    userId: string;
    name: string;
  };
  isLoading: boolean;
  commentPages: WorkplaceCommentsResponse[];
  onUpdate: (comment: WorkplaceCommentType) => void;
  onLoadNextPage?: () => void;
  isFetchingNextPage: boolean;
}

export function CommentsList(props: CommentsListProps) {
  const {
    workPlaceDetails,
    commentPages,
    isLoading,
    onUpdate,
    onLoadNextPage,
    isFetchingNextPage,
  } = props;
  const repliesModalState = useModalState();
  const [commentIdToReply, setCommentIdToReply] = useState<string | undefined>();

  if (isLoading) {
    return (
      <Stack width="100%" spacing={1}>
        {[5, 4, 3, 2, 1].map((key) => (
          <Skeleton key={key} variant="rectangular" width="100%" height={100} />
        ))}
      </Stack>
    );
  }

  let commentToReply: WorkplaceCommentType | undefined;

  commentPages.every((commentPage) => {
    commentToReply = commentPage.data.find((comment) => comment.id === commentIdToReply);
    return !isDefined(commentToReply);
  });

  return (
    <>
      <Box width="100%" alignItems="center">
        {commentPages.map((commentPage) => (
          <Fragment key={commentPage.links.next ?? "comments-last-page"}>
            {commentPage.data.map((comment) => (
              <Fragment key={comment.id}>
                <Divider />
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{
                    marginTop: 2,
                    marginX: 1,
                  }}
                >
                  <WorkerAvatar workerInfo={comment.attributes.workerInfo} />
                  <CommentCard
                    workplaceId={workPlaceDetails.userId}
                    comment={comment}
                    onReply={() => {
                      setCommentIdToReply(comment.id);
                      repliesModalState.openModal();
                    }}
                    onUpdate={onUpdate}
                  />
                </Stack>
              </Fragment>
            ))}
          </Fragment>
        ))}
        {isDefined(onLoadNextPage) && (
          <Button fullWidth size="small" disabled={isFetchingNextPage} onClick={onLoadNextPage}>
            Load More Reviews
          </Button>
        )}
      </Box>

      {commentToReply && (
        <CommentRepliesDialog
          workPlaceDetails={workPlaceDetails}
          comment={commentToReply}
          modalState={repliesModalState}
          onUpdate={onUpdate}
        />
      )}
    </>
  );
}
