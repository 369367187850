import { isDefined } from "@clipboard-health/util-ts";
import BuildIcon from "@mui/icons-material/Build";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LaptopIcon from "@mui/icons-material/Laptop";
import MasksIcon from "@mui/icons-material/Masks";
import PeopleIcon from "@mui/icons-material/People";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SanitizerIcon from "@mui/icons-material/Sanitizer";
import { Button, ListItem, ListItemIcon, ListItemText } from "@mui/material";

import { type QuestionTagType } from "../../api/useGetMultipleChoiceAggregates";
import { QuestionTag } from "../../types";

interface AggregateItemProps {
  headerText: string;
  actionText: string;
  questionTags: QuestionTagType[];
  onClick?: () => void;
}

export function AggregateItem(props: AggregateItemProps) {
  const { headerText, actionText, questionTags, onClick } = props;

  const aggregateItemIconMapping = {
    [QuestionTag.PARKING]: <DirectionsCarIcon fontSize="large" />,
    [QuestionTag.SOFTWARE]: <LaptopIcon fontSize="large" />,
    [QuestionTag.SUPPLIES]: <MasksIcon fontSize="large" />,
    [QuestionTag.DENTAL_ASSISTANT]: <PersonAddIcon fontSize="large" />,
    [QuestionTag.ULTRASONIC]: <BuildIcon fontSize="large" />,
    [QuestionTag.ALLOTTED_FOR_HYGIENE]: <SanitizerIcon fontSize="large" />,
    [QuestionTag.PATIENTS_PER_NURSE]: <PeopleIcon fontSize="large" />,
  };

  const iconMatchingQuestionTag = questionTags.find(
    (tagInfo) => aggregateItemIconMapping[tagInfo.tag]
  );

  return (
    <>
      <ListItem
        sx={{
          paddingBottom: 0,
          borderTop: 1,
          borderColor: (theme) => theme.palette.grey[400],
        }}
      >
        <ListItemIcon sx={{ color: (theme) => theme.palette.common.black }}>
          {iconMatchingQuestionTag ? (
            aggregateItemIconMapping[iconMatchingQuestionTag.tag]
          ) : (
            <InfoOutlinedIcon fontSize="large" />
          )}
        </ListItemIcon>
        <ListItemText primary={headerText} />
      </ListItem>
      <ListItem
        sx={{
          paddingX: 2,
          paddingTop: 0,
        }}
      >
        <Button
          fullWidth
          disableRipple
          disableTouchRipple
          disabled={!isDefined(onClick)}
          variant="text"
          sx={{
            padding: 0,
            textAlign: "left",
          }}
          size="small"
          onClick={() => {
            onClick?.();
          }}
        >
          <ListItemText
            inset
            primary={actionText}
            primaryTypographyProps={{
              color: "inherit",
            }}
          />
        </Button>
      </ListItem>
    </>
  );
}
