import { isDefined } from "@clipboard-health/util-ts";
import { zodResolver } from "@hookform/resolvers/zod";
import SendIcon from "@mui/icons-material/Send";
import { Box, IconButton } from "@mui/material";
import { useToast } from "@src/appV2/lib";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

import { type ThreadAggregate } from "../../api/useGetThreadAggregates";
import { usePostComment } from "../../api/usePostComment";
import { type WorkplaceCommentType } from "../../api/useWorkplaceComments";
import { AnonymousReviewCheckbox } from "../Questions/AnonymousReviewCheckbox";
import { CommentInputField } from "./CommentInputField";

interface ReviewCommentFormProps {
  workplaceId: string;
  inputPlaceholder: string;
  anonymousCheckboxLabel: string;
  parentId?: string;
  questionId?: string;
  onSubmit: (params: { comment?: WorkplaceCommentType; threadAggregate?: ThreadAggregate }) => void;
}

export function ReviewCommentForm(props: ReviewCommentFormProps) {
  const { workplaceId, onSubmit, inputPlaceholder, anonymousCheckboxLabel, parentId, questionId } =
    props;
  const { showErrorToast, showSuccessToast } = useToast();

  const formMethods = useForm<{ comment: string; userWantsToBeAnonymous: boolean }>({
    defaultValues: {
      comment: "",
      userWantsToBeAnonymous: false,
    },
    resolver: zodResolver(
      z.object({
        comment: z.string().min(3),
        userWantsToBeAnonymous: z.boolean(),
      })
    ),
    mode: "onChange",
  });
  const { handleSubmit, formState, reset } = formMethods;

  const { mutate: mutatePostComment, isLoading: commentIsPosting } = usePostComment(workplaceId, {
    onSuccess: ({ data }) => {
      showSuccessToast(
        `Your ${
          isDefined(parentId) || isDefined(questionId) ? "comment" : "question"
        } has been posted`
      );
      reset();
      onSubmit({
        comment: data?.relationships?.parentComment?.data,
        threadAggregate: data?.relationships?.question?.data,
      });
    },
    onError: () => {
      showErrorToast("Something went wrong while posting question");
    },
  });

  return (
    <FormProvider {...formMethods}>
      <Box
        component="form"
        sx={{ width: "100%", textAlign: "center" }}
        onSubmit={handleSubmit((formData) => {
          mutatePostComment({
            text: formData.comment,
            parentId,
            questionId,
            anonymous: Boolean(formData.userWantsToBeAnonymous),
          });
        })}
      >
        <AnonymousReviewCheckbox name="userWantsToBeAnonymous" label={anonymousCheckboxLabel} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: 1,
          }}
        >
          <CommentInputField name="comment" placeholder={inputPlaceholder} />
          <IconButton
            color="inherit"
            size="large"
            type="submit"
            sx={{ padding: 1 }}
            disabled={formState.isSubmitting || !formState.isValid || commentIsPosting}
          >
            <SendIcon fontSize="large" />
          </IconButton>
        </Box>
      </Box>
    </FormProvider>
  );
}
