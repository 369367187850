import { TextField } from "@mui/material";
import { useController } from "react-hook-form";

interface Props {
  name: string;
  placeholder: string;
}

export function CommentInputField(props: Props) {
  const { name, placeholder } = props;
  const {
    field,
    formState: { isSubmitting },
  } = useController({
    name,
  });

  return (
    <TextField
      {...field}
      fullWidth
      multiline
      size="small"
      placeholder={placeholder}
      disabled={isSubmitting}
      InputProps={{
        sx: { borderRadius: 1 },
        inputProps: { maxLength: 1000 },
      }}
    />
  );
}
