import { Text } from "@clipboard-health/ui-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button } from "@mui/material";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { useToast } from "@src/appV2/lib/Notifications/Toasts/useToast";
import { keyBy } from "lodash";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

import { type ReviewQuestion } from "../../api/useGetQuestions";
import { usePostRating } from "../../api/usePostRating";
import { usePostReview } from "../../api/usePostReview";
import { ReviewQuestionType } from "../../types";
import { Questions } from "../Questions";
import { AnonymousReviewCheckbox } from "../Questions/AnonymousReviewCheckbox";
import { RatingField } from "./RatingField";

interface ReviewQuestionnaireFormProps {
  rating: number;
  workPlaceDetails: {
    id: string;
    name: string;
    type: string;
  };
  questions: ReviewQuestion[];
  onSuccess: () => void;
  reviewDate?: Date;
}

const questionnaireFormSchema = z
  .record(z.string(), z.unknown())
  .refine((object: Record<string, unknown>) => {
    // enable submit button if atleast one question is answered
    return Object.values(object).some((value) => {
      if (typeof value !== "string") {
        return false;
      }

      return value?.trim().length > 0;
    });
  });

export function ReviewQuestionnaireForm(props: ReviewQuestionnaireFormProps) {
  const { rating, workPlaceDetails, questions, onSuccess, reviewDate } = props;
  const { showErrorToast, showSuccessToast } = useToast();

  const { mutateAsync: mutatePostReview } = usePostReview(workPlaceDetails.id, {
    onSuccess: ({ data }) => {
      showSuccessToast("Your review has been posted");
      logEvent(APP_V2_USER_EVENTS.WORKPLACE_REVIEWS_REVIEW_SUBMITTED, {
        workplaceId: workPlaceDetails.id,
        answeredQuestionCount: data.data.attributes.count,
        totalQuestionCount: questions.length,
      });
      onSuccess();
    },
  });

  const { mutate: mutatePostRating } = usePostRating(workPlaceDetails.id, {
    onSuccess: ({ data }) => {
      logEvent(APP_V2_USER_EVENTS.WORKPLACE_REVIEWS_RATING_UPDATED, {
        workplaceId: workPlaceDetails.id,
        rating: data.attributes.rating,
      });
    },
  });

  const formMethods = useForm<Record<string, string | boolean | number>>({
    defaultValues: {
      userWantsToBeAnonymous: false,
      rating,
    },
    resolver: zodResolver(questionnaireFormSchema),
    mode: "onChange",
  });
  const { formState, handleSubmit, watch } = formMethods;
  const userWantsToBeAnonymous = watch("userWantsToBeAnonymous");

  return (
    <FormProvider {...formMethods}>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        onSubmit={handleSubmit(async (formData) => {
          if (formData.rating !== rating && typeof formData.rating === "number") {
            mutatePostRating({ rating: formData.rating });
          }

          const answers: Array<{
            questionId: string;
            text?: string;
            optionId?: string;
          }> = [];
          const questionsById = keyBy(questions, "id");
          // send only the answered questions data
          Object.keys(formData).forEach((questionId) => {
            const value = formData[questionId];
            if (
              questionId !== "userWantsToBeAnonymous" &&
              questionId !== "rating" &&
              typeof value === "string" &&
              value?.trim().length > 0
            ) {
              answers.push({
                questionId,
                ...(questionsById?.[questionId]?.attributes?.type ===
                ReviewQuestionType.MULTIPLE_CHOICE
                  ? { optionId: value }
                  : { text: value.trim() }),
              });
            }
          });
          try {
            await mutatePostReview({
              anonymous: Boolean(formData.userWantsToBeAnonymous),
              answers,
              reviewDate: reviewDate?.toISOString(),
            });
          } catch {
            showErrorToast("Something went wrong while posting review");
          }
        })}
      >
        <Box sx={{ textAlign: "center" }}>
          <Box marginY={1}>
            <RatingField name="rating" />
          </Box>
          <Questions questions={questions} />
          <AnonymousReviewCheckbox
            name="userWantsToBeAnonymous"
            label="I want my review to be anonymous"
          />
          <Text variant="caption" sx={{ fontStyle: "italic" }}>
            Reviews that use inappropriate language or contain references to patients and specific
            facility personnel, will be removed. Repeat offenders may be banned from our
            marketplace.
          </Text>
          <Box mt={2}>
            <Button
              fullWidth
              disabled={formState.isSubmitting || !formState.isValid}
              variant="contained"
              type="submit"
            >
              {userWantsToBeAnonymous ? "Post Anonymously" : "Post"}
            </Button>
          </Box>
        </Box>
      </Box>
    </FormProvider>
  );
}
