import { useModalState } from "@clipboard-health/ui-react";
import { List, Skeleton } from "@mui/material";
import { useToast } from "@src/appV2/lib";
import { useQueryClient } from "@tanstack/react-query";
import pluralize from "pluralize";
import { useState } from "react";

import { useGetMultipleChoiceAggregates } from "../../api/useGetMultipleChoiceAggregates";
import {
  getThreadAggregatesUrl,
  type ThreadAggregate,
  useGetThreadAggregates,
} from "../../api/useGetThreadAggregates";
import { AggregateType } from "../../types";
import { AggregateRepliesDialog } from "../Replies/AggregateRepliesDialog";
import { AggregateItem } from "./AggregateItem";

interface AggregatesProps {
  type: AggregateType;
  workPlaceDetails: {
    userId: string;
    name: string;
  };
}

export function Aggregates(props: AggregatesProps) {
  const { type, workPlaceDetails } = props;
  const { showErrorToast } = useToast();
  const repliesModalState = useModalState();
  const [aggregateItemIdToReply, setAggregateItemIdToReply] = useState<string | undefined>();
  const queryClient = useQueryClient();

  const { data: threadAggregates = [], isLoading: isThreadAggregatesLoading } =
    useGetThreadAggregates(workPlaceDetails.userId, {
      enabled: type === AggregateType.THREAD,
      onError: () => {
        showErrorToast("Something went wrong while loading thread aggregates");
      },
    });

  const { data: multipleChoiceAggregates = [], isLoading: isMultipleChoiceAggregatesLoading } =
    useGetMultipleChoiceAggregates(workPlaceDetails.userId, {
      enabled: type === AggregateType.MULTIPLE_CHOICE,
      onError: () => {
        showErrorToast("Something went wrong while loading multiple-choice aggregates");
      },
    });

  const aggregates = type === AggregateType.THREAD ? threadAggregates : multipleChoiceAggregates;

  const aggregateItemToReply = aggregates.find(
    (aggregate) => aggregate.id === aggregateItemIdToReply
  );

  if (
    (type === AggregateType.THREAD && isThreadAggregatesLoading) ||
    (type === AggregateType.MULTIPLE_CHOICE && isMultipleChoiceAggregatesLoading)
  ) {
    return (
      <Skeleton variant="rectangular" width="100%" height={100} aria-label="Loading Aggregates" />
    );
  }

  return (
    <>
      <List
        sx={{
          width: "100%",
          padding: 0,
          "li:last-child": {
            paddingY: 0,
          },
        }}
      >
        {aggregates.map((aggregate) => {
          const { id, attributes } = aggregate;
          return (
            <AggregateItem
              key={id}
              headerText={attributes.text}
              actionText={pluralize(
                type === AggregateType.THREAD ? "reply" : "response",
                attributes.repliesCount,
                true
              )}
              questionTags={attributes.question.tags}
              onClick={() => {
                setAggregateItemIdToReply(id);
                repliesModalState.openModal();
              }}
            />
          );
        })}
      </List>
      {aggregateItemToReply && (
        <AggregateRepliesDialog
          type={type}
          workPlaceDetails={workPlaceDetails}
          aggregate={aggregateItemToReply.attributes}
          modalState={repliesModalState}
          onUpdate={({ updatedThreadAggregate }) => {
            queryClient.setQueryData<ThreadAggregate[]>(
              [getThreadAggregatesUrl(workPlaceDetails.userId)],
              (previousData) => {
                return previousData?.map((threadAggregate) =>
                  threadAggregate.attributes.questionId ===
                  updatedThreadAggregate.attributes.questionId
                    ? { ...threadAggregate, attributes: updatedThreadAggregate.attributes }
                    : threadAggregate
                );
              }
            );
          }}
        />
      )}
    </>
  );
}
