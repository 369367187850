import { type UseModalState } from "@clipboard-health/ui-react";
import { DialogContent } from "@mui/material";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { SlideUpTransition } from "@src/appV2/lib/Animation/SlideUpTransition";
import { FullScreenDialog } from "@src/appV2/lib/Dialogs";

import { FullScreenDialogTitle } from "../../../lib/Dialogs/FullScreen/DialogTitle";
import { type WorkplaceDetails } from "../../types";
import { tagScreenNameDeprecated } from "../../utils";
import { ReviewsSummaryContent } from "../ReviewsSummaryContent";

interface ReviewsSummaryDialogProps {
  modalState: UseModalState;
  workPlaceDetails: WorkplaceDetails;
  screenName: string;
}

export function ReviewsSummaryDialog(props: ReviewsSummaryDialogProps) {
  const { modalState, workPlaceDetails, screenName } = props;

  return (
    <FullScreenDialog modalState={modalState} TransitionComponent={SlideUpTransition}>
      <FullScreenDialogTitle
        onClose={async () => {
          modalState.closeModal();
          await tagScreenNameDeprecated(screenName);

          logEvent(APP_V2_USER_EVENTS.WORKPLACE_REVIEWS_SCREEN_CLOSED, {
            workplaceId: workPlaceDetails.userId,
          });
        }}
      >
        {workPlaceDetails.name}
      </FullScreenDialogTitle>
      <DialogContent sx={{ paddingX: 0 }}>
        <ReviewsSummaryContent workPlaceDetails={workPlaceDetails} />
      </DialogContent>
    </FullScreenDialog>
  );
}
