import { Text } from "@clipboard-health/ui-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import { useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

import { RatingField } from "./RatingField";

interface RatingDialogProps {
  open: boolean;
  onSubmit: (rating: number) => void;
  onClose: () => void;
  workPlaceDetails: {
    id: string;
    name: string;
    type: string;
  };
}

export function RatingDialog(props: RatingDialogProps) {
  const { open, onSubmit, onClose, workPlaceDetails } = props;

  const formMethods = useForm<{ rating: number }>({
    defaultValues: {
      rating: 0,
    },
    resolver: zodResolver(
      z.object({
        rating: z.number(),
      })
    ),
    mode: "onChange",
  });
  const { handleSubmit } = formMethods;

  const formRef = useRef<HTMLFormElement>();

  return (
    <Dialog open={open} onClose={onClose}>
      <FormProvider {...formMethods}>
        <Box
          ref={formRef}
          component="form"
          onSubmit={handleSubmit((formData) => {
            onSubmit(formData.rating);
          })}
        >
          <DialogTitle sx={{ marginTop: 1, padding: 3 }} textAlign="center" variant="h3">
            How was your shift at {workPlaceDetails.name}?
          </DialogTitle>
          <DialogContent sx={{ paddingX: 2, marginBottom: 2 }}>
            <Stack spacing={3} sx={{ alignItems: "center", textAlign: "center" }}>
              <Text variant="h4">Your rating is anonymous</Text>
              <RatingField name="rating" formRef={formRef} />
            </Stack>
          </DialogContent>
        </Box>
      </FormProvider>
    </Dialog>
  );
}
